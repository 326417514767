.flickity-prev-next-button {
  @media screen and (max-width: 600px) {
    top: 42.5% !important;
  }
}

.main-slider {
  margin-bottom: 30px;

  .flickity-viewport {
    height: auto;
    transition: height ease-in-out 0.236s;

    .flickity-slider {
      .slide-cell {
        width: 100%;
        height: 75vh;
        // opacity: 1;

        @media screen and(max-width: 1024px) {
          height: 66.8vh;
        }

        @media screen and(max-width: 768px) {
          height: 37.6vh;
        }

        @media screen and(max-width: 600px) {
          height: 28.1vh;
        }

        .slider-overlay {
          position: absolute;
          background: linear-gradient(
            to top,
            #f1f1f1 0%,
            rgba(255, 255, 255, 0) 25%
          );
          width: 100%;
          height: 100%;
        }

        .slide-cell-content {
          height: inherit;
          width: 100%;
          display: flex;
          align-items: center;
          // opacity: 1;
          // transition: opacity ease-in-out .236s;

          @media screen and(max-width: 1024px) {
            height: auto;
            min-height: auto;
          }

          .slide-text {
            z-index: 9;
            position: absolute;
            opacity: 1;
            transition: opacity ease-in-out 0.236s;

            > div {
              display: flex;
              flex-direction: row;
              align-items: center;

              h2,
              p {
                display: -webkit-box;
                max-width: 100%;
                width: 100%;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        img {
          display: block;
          margin: 0 auto;
          object-fit: cover;
          object-position: center center;
          height: 100%;
          width: 100%;
          opacity: 1;
          transition: opacity ease-in-out 0.236s;

          // @media screen and(max-width: 1024px) {
          //     // height: auto;
          //     // min-height: auto;
          //     height: 100%;
          //     width: 100%;
          //     object-fit: cover;
          // }
        }

        &.is-selected {
          opacity: 1;
          z-index: 0;

          .slide-cell-content {
            opacity: 1;
            transition: opacity ease-in-out 0.236s;

            @media screen and(max-width: 768px) {
              height: auto;
              min-height: auto;
            }

            .slide-text {
              z-index: 99;
            }

            img {
              opacity: inherit;
              transition: opacity ease-in-out 0.236s;

              @media screen and(max-width: 768px) {
                height: auto;
                min-height: auto;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
}
