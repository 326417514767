.home-container {
  margin: 64px 0 0;

  @media screen and (max-width: 767px) {
    padding: 56px 0px;
  }

  @media screen and (max-width: 550px) {
    padding: 0 0 56px 0;
    margin: 56px 0 0;
  }
}

.home-container-cont {
  padding: 0 20px;

  @media screen and (max-width: 420px) {
    padding: 0;
  }
}