.listItemCont {
  color: #000 !important;

  &:hover {
    background-color: #42424249 !important;
  }
}

.selected,
.active {
  .listItemCont {
    background-color: #42424249;
    color: #272a52;

    svg {
      color: #272a52;
    }
  }
}

.logo-img {
  width: auto;
  height: 50px;

  @media screen and (max-width: 600px) {
    height: 40px;
  }

  @media screen and (max-width: 410px) {
    height: 30px;
  }
}

.global-search-container {
  position: fixed;
  width: 400px;
  top: 66px;
  z-index: 99;
  background-color: #565656eb;
  right: 84px;
  padding: 10px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  overflow: auto;

  .global-search-content {
    max-height: 400px;
  }

  .global-search-item-link {
    text-decoration: none;
    color: #fff;

    .global-search-item {
      background-color: transparent;
      padding: 10px 15px;
      transition: all ease-in-out 0.236s;
      align-items: center;
      display: flex;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        background-color: #ffffff2b;
      }
    }
  }
}