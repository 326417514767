.overlay-title {
  z-index: 9;
  color: #ffffff;
  position: absolute;
  width: 80%;
  left: 10%;
  top: 5px;
  bottom: 0;
  right: 0;
  transition: all ease-in-out 0.236s;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: right;
  background: linear-gradient(0deg, transparent 50%, transparent 25%) left
      no-repeat,
    linear-gradient(7deg, transparent 30%, rgba(67, 191, 228, 0.58) 0%) left
      no-repeat,
    no-repeat;
  background-size: 30px 100%, 100% 100%;
  height: 70px;
  line-height: 75px;
  padding: 0 20px;
}

.contenido-slider-container {
  color: rgb(66, 66, 66);
  margin-top: 35px;
  // h1 {
  //     display: grid;
  //     width: 100%;
  //     align-items: center;
  //     text-align: center;
  //     grid-template-columns: minmax(62%, auto) auto minmax(20px, 1fr);
  //     grid-gap: 20px;
  //     font-size: 4rem;
  //     margin-bottom: 25px;
  //     margin-top: 0;

  //     @media screen and (max-width: 768px) {
  //         grid-template-columns: minmax(45%, auto) auto minmax(20px, 1fr);
  //     }
  // }

  // h1:before,
  // h1:after {
  //     content: '';
  //     border-top: 2px solid #272a52;
  // }

  .contenido-slider {
    // .cell {
    //     cursor: pointer;
    //     height: 400px;
    //     width: 100%;

    //     img {
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //     }
    // }

    // .overlay {
    //     background: rgba(0, 0, 0, 0.4);
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     left: 0;
    //     top: 0;
    //     bottom: 0;
    //     right: 0;
    //     opacity: 0;
    //     -webkit-transition: all ease-in-out 0.236s;
    //     -moz-transition: all ease-in-out 0.236s;
    //     transition: all ease-in-out 0.236s;
    // }

    // .contentOverlay {
    //     position: absolute;
    //     text-align: center;
    //     padding-left: 1em;
    //     padding-right: 1em;
    //     width: 100%;
    //     top: 80%;
    //     left: 50%;
    //     opacity: 0;
    //     color: white;
    //     -webkit-transform: translate(-50%, -50%);
    //     -moz-transform: translate(-50%, -50%);
    //     transform: translate(-50%, -50%);
    //     -webkit-transition: all 0.3s ease-in-out 0s;
    //     -moz-transition: all 0.3s ease-in-out 0s;
    //     transition: all 0.3s ease-in-out 0s;

    //     .saberMasButton {
    //         border: 1px solid rgb(255, 255, 255);
    //         color: rgb(255, 255, 255);
    //         padding: 12px 10px;
    //         background-color: rgba(255, 255, 255, 0.075);
    //     }

    //     .saberMasButton:hover {
    //         background-color: rgba(255, 255, 255, 0.452);
    //     }
    // }

    // .cell:hover .overlay {
    //     opacity: 1;
    // }

    // .cell:hover .contentOverlay {
    //     top: 50%;
    //     left: 50%;
    //     opacity: 1;
    // }

    .contenidoLink {
      text-decoration: none;
    }

    .cell {
      cursor: pointer;
      width: 100%;
    }

    .card {
      border-radius: 0px !important;
      margin-bottom: 25px;
      cursor: pointer;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      // min-height: 270px;

      .img-cont {
        position: relative;
        padding-bottom: 56.2%;
      }

      .card-image {
        position: absolute;
        object-fit: fill;
        width: 100%;
        height: 100%;
      }

      .overlay {
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        -webkit-transition: all ease-in-out 0.236s;
        -moz-transition: all ease-in-out 0.236s;
        transition: all ease-in-out 0.236s;
      }

      &:hover .overlay {
        opacity: 1;
      }

      &:hover .content-overlay {
        top: 50%;
        left: 50%;
        opacity: 1;
      }

      &:hover .card-content {
        opacity: 1;
      }

      .content-overlay {
        position: absolute;
        text-align: center;
        padding-left: 1em;
        padding-right: 1em;
        width: 100%;
        top: 80%;
        left: 50%;
        opacity: 0;
        color: white;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;

        .saber-mas-button {
          border: 1px solid rgb(255, 255, 255);
          color: rgb(255, 255, 255);
          padding: 0px 20px;
          background-color: rgba(255, 255, 255, 0.075);
          height: 42px;

          span.span-label {
            padding: 4px 0px 0px 5px;
          }
        }

        .saber-mas-button:hover {
          background-color: rgba(255, 255, 255, 0.452);
        }
      }

      .card-content {
        padding: 0px 15px 10px 15px !important;
        width: inherit;
        position: absolute;
        bottom: 0;
        opacity: 0;
        transition: all ease-in-out 0.236s;
        z-index: 1;
        width: 100%;
      }

      .oprtions {
        padding: 15px 0 0;
      }

      .divider {
        margin-top: 10px;
      }

      .contenido-title {
        color: #ffffff;
        font-size: 1.1rem;
        margin-top: 10px;
        margin-bottom: 0;
        padding-right: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // height: 45px;
        // display: -webkit-box;
        // max-height: 3.2rem;
        // /* autoprefixer: off */
        // -webkit-box-orient: vertical;
        // /* autoprefixer: on */
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: normal;
        // -webkit-line-clamp: 2;
        // line-height: 1.4rem;
        font-weight: 500;
      }

      .contenido-description {
        color: #ffffff;
        font-size: 0.8rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .download-icon {
        color: rgba(255, 255, 255, 0.8);
        width: 2.5rem;
        height: 2.5rem;
        transition: all ease-in-out 0.236s;
      }

      .download-icon:hover {
        color: rgba(255, 255, 255, 1);
      }

      /* md */
      @media (max-width: 1063px) {
        .itemDescription {
          max-height: 100px;
        }
      }
    }
  }
}

.skull {
  .img-cont {
    position: relative;
    padding-bottom: 56.2%;
  }

  .card-image {
    position: absolute;
    object-fit: fill;
    width: 100%;
    height: 100%;
    background-color: rgb(226, 226, 226);
  }
}
